<template>
	<modal-wrapper :close-on-escape="false" @close="close">
		<div class="dialog">
			<h2 style="margin-top:0">Email</h2>

			<!-- INPUT -->
			<ast-input
				id="email-study-recipient-emails"
				v-model="form.to"
				label="Recipient Email(s)"
				placeholder="Separate multiple with commas (,)"
				class="form-group"
			/>
			<ast-input id="email-study-subject" v-model="form.subject" label="Subject" class="form-group" />

			<div class="form-group">
				<ast-checkbox v-model="form.notify">Notify me when images are retrieved</ast-checkbox>
				<ast-checkbox v-model="form.carbonCopy">Send me a copy email</ast-checkbox>
			</div>

			<!-- MESSAGE -->
			<ast-input id="email-study-message" v-model="form.message" class="form-group" type="textarea" />

			<p style="margin-bottom: 15px">
				Sending this e-mail will share report data with all recipients.
			</p>

			<div class="footer right">
				<ast-button type="success" :class="{ disabled: !canSubmitForm }" @click.native="sendEmail">
					Send Email
				</ast-button>
				<ast-button @click.native="close()">Cancel</ast-button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import AstInput from '@components/Input'
import AstCheckbox from '@components/Checkbox'
import consultations from '@services/consultationService'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import GoogleAnalytics from '@services/analyticsService'

const EmailReportDlg = {
	name: 'EmailReportDlg',
	components: {
		AstButton,
		AstInput,
		AstCheckbox,
		ModalWrapper,
	},
	props: {
		reportId: {
			type: String,
			required: false,
		},
		consultantReportId: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			isSubmitting: false,
			form: {
				to: '',
				subject: '',
				notify: false,
				carbonCopy: false,
				message: '',
			},
		}
	},
	computed: {
		canSubmitForm() {
			let frm = this.form
			return (
				!(!frm.to || frm.to.length === 0) && // not null, empty, or undefined
				!(!frm.subject || frm.subject.length === 0) &&
				frm.message !== null &&
				frm.message !== undefined &&
				!this.isSubmitting
			)
		},
	},
	methods: {
		clearForm() {
			this.form = {
				to: '',
				subject: '',
				notify: false,
				carbonCopy: false,
				message: '',
			}
		},
		close() {
			dlg.close(this, true, false)
		},
		async sendEmail() {
			this.isSubmitting = true
			try {
				let data = {
					consultantReportId: this.consultantReportId,
					reportId: this.reportId,
					recipients: this.form.to
						.split(/[;,]+/) // Turn into array
						.filter(entry => entry.trim() !== '') // Remove empty
						.map(e => e.trim()), // Trim each address
					subject: this.form.subject,
					message: this.form.message,
					notify: this.form.notify,
					carbonCopy: this.form.carbonCopy,
				}
				const response = await consultations.shareByEmail(data)
				// this.$notifications.addInfo(data)
				try {
					GoogleAnalytics.sendGAEvent('Teleconsultation', {
						event_category: 'Share by Email',
						event_label: 'Share by Email',
					})
				}
				catch (err) {
					console.log(err)
				}
				dlg.close(this, true, true)
			} finally {
				this.isSubmitting = false
			}
		},
	},
}

export default EmailReportDlg

export function openEmailReportDlg(reportId, consultantReportId) {
	return dlg.open(EmailReportDlg, { reportId, consultantReportId })
}
</script>

<style lang="scss" scoped>
.title-bar {
	margin-bottom: 15px;

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

.form-group {
	margin-bottom: 15px;
}

.control {
	margin-bottom: 5px;
}
</style>
