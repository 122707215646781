

































































































































































import { mapState, mapActions } from 'vuex'
import store from '@store'
import AstCard from '@components/Card.vue'
import TeleconsultationThumbnails from '@components/TeleconsultationThumbnails.vue'
import ModalDrawer from '@components/ModalDrawer.vue'
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import DataTable from '@components/DataTable.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { openEmailReportDlg } from '@reporting/dialogs/EmailReportDlg.vue'
import consultations from '@services/consultationService'
import GoogleAnalytics from '@services/analyticsService'

let nextReport: IImageOnlyReport = null

export default {
	name: 'ImageOnlyReport',
	components: {
		AstCard,
		DataTable,
		ModalDrawer,
		TeleconsultationThumbnails,
		AstToolbar,
		ToolbarButton,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isActionsOpen: false,
			report: null,
			isPageLoading: false,
		}
	},
	computed: {
		...mapState({
			claims: (state: any) => state.auth.claims,
			permissions: (state: any) => state.static.permissions,
		}),
		thumbnails() {
			return this.report.images.map(i => ({
				imageId: i.id,
				seriesId: i.seriesId,
				seriesNumber: i.seriesNumber,
				simpleName: i.simpleName,
				numberOfImages: i.numberOfImages,
				storageLocation: i.storageLocation,
			}))
		},
		attachments() {
			return this.report.attachments.map(i => ({
				imageId: i.imageId,
				seriesId: i.seriesId,
				description: i.description,
				fileExtension: i.fileExtension,
				storageLocation: i.storageLocation,
			}))
		},
	},
	watch: {
		id() {
			this.loadReport()
		},
	},
	async beforeRouteEnter(to, from, next) {
		try {
			nextReport = await consultations.getImageOnlyReport(to.params.id)
			if (!nextReport) return next(from.name ? false : '/teleconsultations')
			next()
		} catch (err) {
			showAlert(err)
			next(from.name ? false : '/teleconsultations')
		}
	},
	created() {
		this.loadReport()
	},
	methods: {
		...mapActions(['openStudy']),
		redirectToList() {
			this.forceNavigation = true
			this.$router.push({ name: 'teleconsultations' })
		},
		getAddress(key) {
			// gets a formatted address for this.report.consultant or this.report.client
			if (!this.report[key]) return ''
			let address = ''
			const c = this.report[key]
			if (c.address) address += c.address
			if (c.city || c.state) {
				address += '<br />' + [c.city, c.state].filter(Boolean).join(', ')
				if (c.postalCode) address += ' ' + c.postalCode
			}
			return address
		},
		openEmailReportDlg() {
			openEmailReportDlg(null, this.id)
		},
		openViewer() {
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
			this.openStudy({
				clinicCode: this.claims.activeClinicCode,
				reportId: this.report.reportId,
				modality: this.report.study.modality,
			})
		},
		async loadReport(reportId: string) {
			if (!reportId) reportId = this.id
			this.isPageLoading = true
			this.report = nextReport || (await consultations.getImageOnlyReport(reportId))
			nextReport = null
			this.isPageLoading = false
		},
		downloadReport(imageType) {
			this.$api.file.downloadReport({
				downloadType: imageType === 'previewImage' ? 0 : 1,
				clinicCode: this.claims.activeClinicCode,
				id: this.report.reportId,
			})
		},
	},
}
