
























































































import { mapState, mapActions } from 'vuex'
import store from '@store'
import AstCard from '@components/Card.vue'
import TeleconsultationThumbnails from '@components/TeleconsultationThumbnails.vue'
import ModalDrawer from '@components/ModalDrawer.vue'
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { openEmailReportDlg } from '@reporting/dialogs/EmailReportDlg.vue'
import salesService from '@services/salesService'
import GoogleAnalytics from '@services/analyticsService'

let nextReport = null

export default {
	name: 'SaleSubmission',
	components: {
		AstCard,
		ModalDrawer,
		TeleconsultationThumbnails,
		AstToolbar,
		ToolbarButton,
	},
	filters: {
		url(value) {
			if (value.substring(0, 4).toLowerCase() !== 'http') {
				value = 'http://' + value
			}
			return value
		},
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isActionsOpen: false,
			report: null,
			isPageLoading: false,
		}
	},
	computed: {
		...mapState({
			claims: (state: any) => state.auth.claims,
			permissions: (state: any) => state.static.permissions,
		}),
		statusHeading() {
			let report = this.report
			if (report && report.entry && report.entry.saleName) {
				let heading = `Submitted to ${report.entry.saleName}`
				if (report.entry.label) heading += ` (${report.entry.label})`
				return heading
			}
			return 'Submitted'
		},
		consultantAddress() {
			let address = ''
			const { consultant } = this.report
			if (consultant.address) address += consultant.address
			if (consultant.city || consultant.state) {
				address += '<br />' + [consultant.city, consultant.state].filter(Boolean).join(', ')
				if (consultant.postalCode) address += ' ' + consultant.postalCode
			}
			return address
		},
		hipNumber() {
			return this.translate('consignerStudy') + ' ' + this.report?.entry?.patientId
		},
		thumbnails() {
			return this.report.images.map(i => ({
				imageId: i.id,
				seriesId: i.seriesId,
				seriesNumber: i.seriesNumber,
				simpleName: i.simpleName,
				numberOfImages: i.numberOfImages,
				storageLocation: i.storageLocation,
			}))
		},
		attachments() {
			return this.report.attachments.map(i => ({
				imageId: i.imageId,
				seriesId: i.seriesId,
				description: i.description,
				fileExtension: i.fileExtension,
				storageLocation: i.storageLocation,
			}))
		},
	},
	watch: {
		id() {
			this.loadReport()
		},
	},
	async beforeRouteEnter(to, from, next) {
		try {
			nextReport = await salesService.getSalesInfo(to.params.id)
			if (!nextReport) return next(from.name ? false : '/teleconsultations')
			next()
		} catch (err) {
			showAlert(err)
			next(from.name ? false : '/teleconsultations')
		}
	},
	created() {
		this.loadReport()
	},
	methods: {
		...mapActions(['openStudy']),
		redirectToList() {
			this.forceNavigation = true
			this.$router.push({ name: 'teleconsultations' })
		},
		openEmailReportDlg() {
			openEmailReportDlg(null, this.id)
		},
		openViewer() {
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
			this.openStudy({
				clinicCode: this.claims.activeClinicCode,
				reportId: this.report.reportId,
			})
		},
		async loadReport(reportId: string) {
			if (!reportId) reportId = this.id
			this.isPageLoading = true
			this.report = nextReport || (await salesService.getSalesInfo(reportId))
			nextReport = null
			this.isPageLoading = false
		},
		downloadReport(imageType) {
			this.$api.file.downloadReport({
				downloadType: imageType === 'previewImage' ? 0 : 1,
				clinicCode: this.claims.activeClinicCode,
				id: this.report.reportId,
			})
		},
	},
}
